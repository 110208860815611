// components/Protected.js
import { useAuthenticator, Heading } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { RingLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { CognitoUser } from '@aws-amplify/auth';


export function Protected() {
  const { route } = useAuthenticator((context) => [context.route]);

  

  const message =
    route === 'authenticated' ? 'FIRST PROTECTED ROUTE!' : 'Loading...';

  const [isLoaded, setIsLoaded] = useState(false)
  const [user, setUser] = useState<CognitoUser>()
  const [notificationID, setNotificationID] = useState("")


  useEffect(() => {
    const getData = async () => {
      Auth.currentAuthenticatedUser().then(
        user => {
          console.log(user.attributes.email)
          setUser(user!.attributes.email)
        })
      .catch(
        err => {
          setNotificationID(Store.addNotification({
            title: "Sorry!",
            message: "We encountered an issue",
            type: "danger",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true
            }
          }));

          Store.removeNotification(notificationID)
      });
      
      setIsLoaded(true)
      

    }
    getData()
  }, [])


  return (
    <>
      {isLoaded ? (
        <>
          <Heading level={1}>{message}</Heading>
          Hey<br/>
          {user}
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><RingLoader color="#36d7b7" /></div>

        </div>
      )}
    </>


  );
}
