
import "../../layout/footer/Footer.css";
import { createMuiTheme, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { TextField } from "@aws-amplify/ui-react";
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function Footer() {

    return (
        <>
            <Stack direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}>
                <footer className="footer-con" style={{ color: "gray", marginBottom: "0px", width: '100%', }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3} xl={3}>
                            <Item>
                                <div className="container footer-1">
                                    <Typography variant="h2">TRADING HOURS</Typography>


                                    <Typography variant="h3">Monday-Thursday:  08:00-16:30</Typography>
                                    <Typography variant="h3" mt={2}>Friday:  08:00-15:00</Typography>
                                </div>

                            </Item>
                        </Grid>

                        <Grid item xs={12} md={3} xl={3}>
                            <Item>
                                <div className="container footer-2">
                                    <Typography variant="h2">ADDRESS</Typography>

                                    <Typography variant="h3">6 Jules St, Jeppestown, Johannesburg, 2043</Typography>
                                    <Typography variant="h3">11 Hanau St, Jeppestown, Johannesburg, 2043</Typography>
                                </div>
                            </Item>
                        </Grid>

                        <Grid item xs={12} md={3}  xl={3}>
                            <Item>
                                <div className="container footer-3">
                                    <Typography variant="h2">CONTACT US</Typography>

                                    <Typography variant="h3">0116140505</Typography>
                                    <Typography variant="h3">info@novaslubricants.co.za</Typography>
                                </div>
                            </Item>
                        </Grid>

                        <Grid item xs={12} md={3} xl={3}>
                            <Item>
                                <div className="container footer-4">
                                    <Typography variant="h2">LET US CALL YOU BACK!</Typography>

                                    <TextField
                                        required
                                        id="outlined-required"
                                        defaultValue="Enter text here" label={undefined} />
                                    <Button variant="contained">Send</Button>
                                </div>
                            </Item>
                        </Grid>
                    </Grid>
                </footer>
            </Stack>
        </>
    );
}