// components/Home.js
import { Heading } from '@aws-amplify/ui-react';
import { Link, useParams } from 'react-router-dom';
import { RingLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { getItem } from '../../services/api.svs';
import './products.css';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { useNavigate } from 'react-router-dom';


export function Products() {

  const navigate = useNavigate();
  const params = useParams();

  const [isLoaded, setIsLoaded] = useState(false)
  const [products, setProducts] = useState<any[]>([])
  const [productType, setProductType] = useState<any>({})


  useEffect(() => {
    window.scrollTo(0, 0);
    const getProducts = async () => {
      // const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem(`/product_types/${params.productTypeName}`).then(resp => {
        setProductType(resp.data)
        getItem(`/products/productTypeName/${params.productTypeName}`).then(resp => {
          setProducts(resp.data)
          console.log(resp.data)
          setIsLoaded(true)
        }).catch((resp) => {
          navigate('/no-such-products');
        })
       
      }).catch((resp) => {
        navigate('/no-such-products');
        
      })
    }
    getProducts()
  }, [])

  const sortedProducts = products.slice().sort((a, b) => {
    const nameA = a.productName.toUpperCase(); // ignore upper and lowercase
    const nameB = b.productName.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0; // names must be equal
  });


  return (
    <>


      {isLoaded ? (
        <>
          <Container maxWidth="xl" style={{paddingTop: "30px"}}>
            <div className="dd" style={{
              backgroundColor: "#fff",
            }}>
              <Heading level={3} color="#000">
                {productType.productTypeName}
                <br />
              </Heading>

              <Grid className="products-box">
                <div style={{ width: "100%" }}>
                  <Button
                    component={Link}
                    to="/product-types"
                    color="primary"
                    variant="outlined"
                    sx=
                    {{
                      height: "20px",
                      marginTop: 5,
                      marginBottom: 1,
                      marginLeft: { xs: -10, sm: -40, md: -80, lg: -120, xl: -120 },
                      border: "none",
                      '&:hover': {
                        backgroundColor: "#84878C",
                        border: "2px solid #84878C",
                        color: "#000"
                      }
                    }}
                    startIcon={<ArrowBackIcon />}
                  >Product Range</Button>
                </div>
              </Grid>

              <div className="dd" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: "100%"
              }}>
                <Grid container spacing={3}
                  alignItems="center"
                  justifyContent="center"
                  style={{ width: "95%" }}>
                  {sortedProducts.map((product) =>
                    <Grid item lg={2.4} md={4} xs={12}>
                      <Card >
                        <CardActionArea>
                          <Typography variant="h2" fontSize={"17px"} color="primary" sx={{ marginTop: 3, fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, height: "5vh" }}>
                            {product.productName}
                          </Typography>
                          <a href={`/products/${params.productTypeName}/${product.productId}`}>
                            <CardMedia
                              component="img"
                              height="auto"
                              image={process.env.REACT_APP_NOVAS_IMAGE_BASE + product.productUrlSmall}
                              alt={product.productName}
                              sx={{ height: 300, objectFit: "contain", width: "100%", display: 'flex' }}
                            />
                          </a>
                        </CardActionArea>

                        <CardActions>
                          <Button component={Link} to={`/products/${params.productTypeName}/${product.productId}`}
                            size="small" color="primary" sx={{
                              display: "flex",
                              backgroundColor: "#fff",
                              textDecoration: "none",
                              border: "#000 1px solid",
                              borderRadius: "4px",
                              height: "30px",
                              width: "210px",
                              margin: "13px auto",
                              '&:hover': {
                                backgroundColor: "#84878C",
                                border: "2px solid #84878C",
                                color: "#000"
                              }
                            }} endIcon={<ArrowForwardIcon />}>
                            View product
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>)}
                </Grid>
              </div>
              <br />
            </div>
          </Container>

        </>

      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><RingLoader color="#36d7b7" /></div>

        </div>
      )
      }
    </>
  );
}