// components/Home.js
import { Heading } from '@aws-amplify/ui-react';
import Maps from '../../components/maps/Maps';
import { useEffect, useState } from 'react';
import './contactUs.css';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import SendIcon from '@mui/icons-material/Send';
import { postItem } from "../../services/api.svs";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import * as Sentry from "@sentry/react";


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none'
}));


export function ContactUs() {

  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
      setIsLoaded(true)
  }, [])

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [topic, setTopic] = useState('')
  const [message, setMessage] = useState('')
  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [numberError, setNumberError] = useState(false)
  const [topicError, setTopicError] = useState(false)
  const [messageError, setMessageError] = useState(false)



  const handleSubmit = (e: any) => {
    setIsLoaded(false)
    e.preventDefault()
    setNameError(false)
    setEmailError(false)
    setNumberError(false)
    setTopicError(false)
    setMessageError(false)

    const formData = new FormData();
    formData.append("customerName", name)
    formData.append("email", email)
    formData.append("number", number)
    formData.append("topic", topic)
    formData.append("message", message)

    if (name === '') {
      setNameError(true)
    }

    if (email === '') {
      setEmailError(true)
    }

    if (number === '') {
      setNumberError(true)
    }

    if (topic === '') {
      setTopicError(true)
    }

    if (message === '') {
      setMessageError(true)
    }

    if (name && email && number && topic && message) {
      console.log(name, email, number, topic, message)
    }

    // const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
    postItem('/messages', formData).then(resp => {
      if (resp.data.topic === topic) {
        setIsLoaded(true)
        
      }
    }).catch(err => {
      Sentry.captureException(err)
      setIsLoaded(true)
    })


  }


  return (
    <>
      {isLoaded ? (
        <>
          <Container maxWidth="xl">
            <div className="contact-page-container">
              <Heading className='heading' level={3} marginTop="8rem">
                Contact Us
              </Heading>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="contact-inner">
                    <Item>
                      <Typography variant="h2" mt={13} sx={{align: {xs: "center", sm : "center", md: "center", lg: "left", xl: "left"}}}>Call us on:</Typography>
                      <Typography
                        variant="h3"
                        mt={6}
                        sx={{
                          fontSize:
                            { xs: 16, sm: 20, md: 20, lg: 24, xl: 24 },
                            ml: { xs: "5px", sm: "-220px", md: "-250px",lg: "-90px", xl:"-90px"},
                            align: {xs: "left", sm: "left", md: "center", lg: "center", xl: "center"}
                        }}>Novas Lubricants: <Typography variant="h6" className="text-style" fontWeight={400} sx={{ fontSize: { xs: 18, sm: 22, md: 20, lg: 23, xl: 23 }, mt: { xs: 0, sm: -4, md: -3.6, lg: -3.6, xl: -3.6 }, ml: { xs: 3, sm: 56, md: 60, lg: 60, xl: 60 } }}><a href='tel:011-614-0505' className="ctn-number">011-614-0505</a></Typography></Typography>
                      <Typography
                        variant="h3"
                        mb={12}
                        sx={{
                          fontSize:
                            { xs: 16, sm: 20, md: 20, lg: 24, xl: 24 },
                            ml: { xs: "5px", sm: "-220px", md: "-250px",lg: "-90px", xl:"-100px"},
                            align: {xs: "left", sm: "left", md: "center", lg: "center", xl: "center"},
                          mt: { xs: 7, sm: 5, md: 10, lg: 8, xl: 9 }
                        }}> Novas Industrial: <Typography variant="h6"  fontWeight={400} sx={{ fontSize: { xs: 18, sm: 22, md: 20, lg: 23, xl: 23 }, mt: { xs: 0, sm: -4, md: -3.6, lg: -3.6, xl: -3.6 }, ml: { xs: 3, sm: 56, md: 60, lg: 60, xl: 60 } }}><a href='tel:011-614-0505' className="ctn-number">011-614-0505</a></Typography></Typography>

                      <Typography
                        variant="h2"
                        mt={14}
                        className="email"
                        sx={{align: {xs: "center", sm : "center", md: "center", lg: "left", xl: "left"}}}>Email us:</Typography>
                      <Typography
                        variant="h3"
                        mt={8}
                        sx={{
                          align: {xs: "left", sm: "left", md: "center", lg: "center", xl: "center"},
                          ml: {xs: "-4px", sm: "-280px", md: "-370px", lg: "-250px", xl:"-250px"},
                          mb: { xs: 12, sm: 14 },
                          fontSize: { xs: 16, sm: 20, md: 20, lg: 24, xl: 24 }
                        }} >Email:<Typography variant="h5" className="text-style" sx={{ fontSize: { xs: 17, sm: 22, md: 22, lg: 24, xl: 24 }, mt: { xs: 3, sm: -3.5, md: -3.4, lg: -3.6, xl: -3.6 }, ml: { xs: 1, sm: 50, md: 70, lg: 25, xl: 60 } }}><a className="ctn-number" href='mailto:info@novaslubricants.co.za'>info@novaslubricants.co.za</a></Typography></Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="contact-inner">
                    <Item>
                     
                        <Container>
                        <Grid className="contact-details"
                            xs={12} sm={12} md={12} lg={6} xl={6} ml={13}
                            sx={{ ml: { xs: "auto", sm: 13, md: 7, lg: 13, xl: 13 }, display: "flex", justifyContent: "center" }}> <Typography variant='h2' mt={13} sx={{ml: {xs: "3px", sm: "0px", md: "20px", lg: "55px", xl: "55px"}}} mb={5}>Or send us a message</Typography></Grid>
                       
                          <Grid className="contact-details"
                            xs={12} sm={12} md={12} lg={6} xl={6} ml={13}
                            sx={{ ml: { xs: "auto", sm: 13, md: 7, lg: 13, xl: 13 }, display: "flex", justifyContent: "center" }}>

                            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                              <TextField
                                onChange={(e) => setName(e.target.value)}
                                label="Name"
                                error={nameError}
                                variant="outlined"
                                required
                                sx={{
                                  width:
                                    { xs: 200, sm: 200, md: 250, l: 300, xl: 350 }, textAlign: "center",
                                  "& .MuiInputBase-root": {
                                    height:
                                      { xs: 50, sm: 50, md: 60, l: 60, xl: 60 }
                                  },
                                  mt: 2,
                                  mb: 3
                                }}
                              />

                              <TextField
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete='email'
                                label="Email"
                                variant="outlined"
                                required
                                error={emailError}
                                sx={{
                                  width:
                                    { xs: 200, sm: 200, md: 250, l: 300, xl: 350 },
                                  "& .MuiInputBase-root": {
                                    height:
                                      { xs: 50, sm: 50, md: 60, l: 60, xl: 60 }
                                  },
                                  mb: 3
                                }}
                              />

                              <TextField
                                onChange={(e) => setNumber(e.target.value)}
                                label="Number"
                                variant="outlined"
                                required
                                error={numberError}
                                sx={{
                                  width:
                                    { xs: 200, sm: 200, md: 250, l: 300, xl: 350 },
                                  "& .MuiInputBase-root": {
                                    height:
                                      { xs: 50, sm: 50, md: 60, l: 60, xl: 60 }
                                  },
                                  mb: 3
                                }}
                              />

                              <TextField
                                onChange={(e) => setTopic(e.target.value)}
                                label="Topic"
                                variant="outlined"
                                required
                                error={topicError}
                                sx={{
                                  width:
                                    { xs: 200, sm: 200, md: 250, l: 300, xl: 350 },
                                  "& .MuiInputBase-root": {
                                    height:
                                      { xs: 50, sm: 50, md: 60, l: 60, xl: 60 }
                                  },
                                  mb: 3
                                }}
                              />

                              <TextField
                                onChange={(e) => setMessage(e.target.value)}
                                label="Message"
                                variant="outlined"
                                required
                                error={messageError}
                                multiline
                                rows={2}
                                sx={{
                                  width:
                                    { xs: 200, sm: 200, md: 250, l: 300, xl: 350 },
                                  "& .MuiInputBase-root": {
                                    height:
                                      { xs: 80, sm: 80, md: 90, l: 90, xl: 90 }
                                  }
                                }} />

                              <Button
                                onClick={handleSubmit}
                                className="btn ctn-btn add-back-btn text-right btn-submit"
                                type="submit"
                                size='medium'
                                variant="contained"
                                sx={{
                                  bgcolor: 'primary.dark',
                                  mt: 4,
                                  width: 130
                                }}
                                endIcon={<SendIcon />}
                              >Submit</Button>
                            </form>
                          </Grid>
                        </Container>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
              <br />

              <div className="row contact-section ">
                <Container>
                  <Stack
                    direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                    className="row contact-section">
                    <Grid
                      className="col-lg-6 col-md-12 col-xs-12"
                      mt={6}>
                      <Typography
                        variant="h2"
                        align="center"
                        fontSize={28}>
                        Novas Lubricants</Typography>

                      <div className="map-styling">
                        <Maps lat={-26.207537} lng={28.061745} heigh={"500px"} width={"80%"} />
                      </div>


                      <Typography
                        variant="h6"
                        fontWeight={"normal"}
                        className="address"
                        mt={2}
                        sx={{
                          fontSize: { xs: 11, sm: 15, md: 17, lg: 22, xl: 20 },
                          ml: { xs: 1, sm: 6, md: 10, lg: 3, xl: 7 }
                        }}>
                        6 Jules St, Jeppestown, Johannesburg, 2043
                      </Typography>

                      <Typography
                        variant="h4"
                        fontWeight={"normal"}
                        className="contact-person"
                        sx={{
                          fontSize: { xs: 13, sm: 15, md: 17, lg: 24, xl: 24 },
                          ml: { xs: 2, sm: 6, md: -43, lg: 3, xl: 7 },
                          mt: { xs: 2, sm: 1, md: 8, lg: 4, xl: 3 },
                          mr: { xs: 0, sm: 0, md: 12, lg: -8, xl: 4 }
                        }}>
                        Marius: <span ><a href='tel:083-456-3745' className="ctn-number">083-456-3745</a></span>
                      </Typography>
                    </Grid>

                    <Grid
                      xs={12} sm={12} md={12} lg={6} xl={6}
                      mt={6}
                      className="col-lg-6 col-md-12 col-xs-12">
                      <Typography
                        variant="h2"
                        align="center"
                        fontSize={28}>Novas Industrial</Typography>

                      <div className="map-styling">
                        <Maps lat={-26.207631} lng={28.06477} heigh={"500px"} width={"80%"} />
                      </div>


                      <Typography
                        variant="h6"
                        fontWeight={"normal"}
                        className="address"
                        mt={2}
                        sx={{
                          fontSize: { xs: 11, sm: 15, md: 17, lg: 22, xl: 20 },
                          ml: { xs: 1, sm: 6, md: 9, lg: 3, xl: 7 }
                        }}>
                        11 Hanau St, Jeppestown, Johannesburg, 2043
                      </Typography>

                      <Typography
                        variant="h4"
                        fontWeight={"normal"}
                        className="contact-person"
                        mt={1} ml={3}
                        sx={{
                          fontSize: { xs: 13, sm: 15, md: 17, lg: 24, xl: 24 },
                          ml: { xs: 2, sm: 6, md: -45, lg: 3, xl: 7 },
                          mt: { xs: 1, sm: 1, md: 6, lg: 3, xl: 3, },
                          mb: 8,
                          mr: { xs: 3, sm: 0, md: 0, lg: 0, xl: 0 }
                        }}>
                        Kenneth: <span><a href='tel:082-495-1729' className="ctn-number">082-495-1729</a></span>
                      </Typography>
                    </Grid>
                  </Stack>
                </Container>
                <br />
              </div>
            </div>
          </Container>

        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
        </div>
      )}
    </>

  );
}
