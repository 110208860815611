// components/Home.js
import { Heading } from '@aws-amplify/ui-react';
import { RingLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './faq.css';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Container from '@mui/material/Container';


export function FAQ() {
  const [isLoaded, setIsLoaded] = useState(true)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <>
      {isLoaded ? (
        <>
          <Container className='cd' maxWidth="xl">
            <Heading className='faq-header' level={3} marginTop="8rem">
              Frequently Asked Questions
            </Heading>
            <Container sx={{ marginTop: "90px" }}>
              <div className='accordion-con'>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h6" className='accordion-header' sx={{ fontSize: { xs: 15, sm: 16, md: 18, lg: 20, xl: 20 }, padding: 3 }}>
                      <QuestionMarkIcon sx={{ marginRight: 1 }} />
                      Can we purchase products at your factory?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" className='accordion-body' sx={{ fontSize: { xs: 14, sm: 16, md: 16, lg: 17, xl: 19 }, padding: 2 }}>
                      We exclusively supply retail stores and wholesalers. We can supply oil in bulk tankers, flowbins(IBC), drums and 20L containers.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography variant="h6" className='accordion-header' sx={{ fontSize: { xs: 14, sm: 17, md: 18, lg: 20, xl: 20 }, padding: 3 }}>
                      <QuestionMarkIcon sx={{ marginRight: 1 }} />
                      Do you do deliveries?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" className='accordion-body' sx={{ fontSize: { xs: 14, sm: 17, md: 17, lg: 19, xl: 19 }, padding: 2 }}>
                      We do and strive to deliver within 48 hours of confirmation of payment. We do bulk deliveries.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Container>
          </Container>

        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><RingLoader color="#36d7b7" /></div>

        </div>


      )}
    </>


  );
}
