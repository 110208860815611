// components/Home.js
import { Heading } from '@aws-amplify/ui-react';
import { RingLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getItem } from '../../services/api.svs';
import './productDetails.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, CardActions, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import makro from "../../images/businessPartners/Makro.webp";
import leroyMerlin from "../../images/businessPartners/leroy-merlin.webp";
import takealot from "../../images/businessPartners/TakeAlot.webp";

import makro1 from "../../images/businessPartners/makro logo1.jpg";
import leroyMerlin1 from "../../images/businessPartners/leroyMerlin1.jpg";
import takealot1 from "../../images/businessPartners/takealot1.png";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',

}));


export function ProductDetails() {

  const navigate = useNavigate();
  const params = useParams();

  const [isLoaded, setIsLoaded] = useState(false)
  const [productDetails, setProductDetails] = useState<any>([])
  const [productType, setProductType] = useState<string>()

  useEffect(() => {
    window.scrollTo(0, 0);
    // const pathname = window.location.pathname; // Get the pathname of the URL
    // const parts = pathname.split('/'); // Split the pathname by '/'
    // const productType = parts[2]; // Get the product type from the URL (e.g., 'Aerosols')

    const getProductDetail = async () => {
      // const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem(`/products/${params.productId}`).then(resp => {
        console.log(resp.data)
        setProductType(resp.data.productTypeId)
        setProductDetails(resp.data)
        setIsLoaded(true)
      }).catch(err => {
        navigate('/no-such-product');
      })
      //stop loading when data is fetched
    }
    getProductDetail()

  }, [])

  const imageStyle = {
    margin: '0 auto', // Center the image horizontally
    height: '30vh',
    display: 'block', // Ensures the image behaves as a block element
  };

  const mainLeftStyle = {
    display: 'flex',
    justifyContent: 'center',
  };


  return (
    <>
      {isLoaded ? (
        <>
          <div className="detail-container" >

            <div className="main">
            </div>
          </div>

          <Grid container spacing={2}>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} mt={6} className="detail-title">
              <div>
                <Typography variant="h1" color="primary" sx={{ fontSize: { xs: 22, sm: 25, md: 25, lg: 34, xl: 34 } }} >{productDetails.productName}</Typography>

                {/* <Typography variant='h6' className="back-nav" sx={{ fontSize: { xs: "13px", sm: "17px", md: "19px", lg: "22px", xl: "22px" } }}>
                  <Link to={`/products/${productDetails.productType.productTypeName}`}>
                    <i className="bi bi-arrow-left">{productDetails.productType.productTypeName}</i>
                  </Link>
                </Typography> */}

                <Button
                  component={Link}
                  to={`/products/${productType}`}
                  color="primary"
                  variant="outlined"
                  sx=
                  {{
                    height: "20px",
                    marginTop: { xs: 8, sm: 5, xl: 7.5 },
                    marginBottom: 1,
                    marginLeft: { xs: -16, sm: -60, md: -80, lg: -120, xl: -120 },
                    border: "none",
                    '&:hover': {
                      backgroundColor: "#84878C",
                      border: "2px solid #84878C",
                      color: "#000"
                    }
                  }}
                  startIcon={<ArrowBackIcon />}
                >Product Range</Button>
              </div>
            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Item className="main-left">
                <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img src={process.env.REACT_APP_NOVAS_IMAGE_BASE + productDetails.productUrl} alt={productDetails.productName} />
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
              <Item className="main-right" style={{ overflow: "auto" }}>
                <div >

                  <Typography variant="body1" className="product-description" color="primary" mt={2} sx={{ fontSize: { xs: 14, sm: 16, md: 17, lg: 19, xl: 19 }, ml: { xs: 0, sm: 2, md: 4, lg: 2, xl: 2 } }}>
                    {productDetails.description}
                  </Typography>

                  <div className="product-benefits">
                    <Typography variant="body1" color="primary" sx={{ fontSize: { xs: 13, sm: 13, md: 13, lg: 18, xl: 18 } }} mt={2}>
                      <ul>
                        {productDetails.benefits.map((benefit: string) =>
                          <li>
                            {benefit.replaceAll("\'", "")}
                          </li>)}
                      </ul>
                    </Typography>
                  </div>

                  <Typography
                    variant="h5"
                    mt={3}
                    align="left"
                    sx={{
                      fontSize: { xs: 19, sm: 19, md: 21, lg: 20, xl: 21 },
                      ml: "20px",
                      textAlign: 'center', // Default to center alignment

                      '@media (min-width: 600px)': {
                        // Adjust the alignment for larger screens
                        textAlign: 'left',
                      },
                    }}
                  >
                    Available Sizes:
                  </Typography>
                  <br />
                  <Grid container spacing={2} justifyContent="center">
                    {productDetails.sizes.map((size: string, index: number) => (
                      <Grid item xs={6} sm={4} md={3} key={index}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: { xs: 14, sm: 17, md: 17, lg: 20, xl: 20 },
                            textAlign: 'center',
                            paddingBottom: '5px',
                          }}
                        >
                          {size}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </div>
                <br />

                <Grid className='logoContainer' sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
                  {productDetails.takealotUrl && productDetails.makroUrl ? (
                    <>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" justifyContent="center" className="partnerLogos1">
                          {productDetails.makroUrl !== null && productDetails.makroUrl !== undefined && productDetails.makroUrl !== '' ? <a target="_blank" href={productDetails.makroUrl}> <img src={makro1} style={{ width: '100%', height: 'auto' }} /></a> : <></>}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" justifyContent="center" className="partnerLogos1">
                          {productDetails.takealotUrl !== null && productDetails.takealotUrl !== undefined && productDetails.takealotUrl !== '' ? <a target="_blank" href={productDetails.takealotUrl}> <img src={takealot1} style={{ width: '100%', height: 'auto' }} /></a> : <></>}
                        </Box>
                      </Grid>
                    </>
                  ) : productDetails.takealotUrl || productDetails.makroUrl ? (
                    <Grid item xs={12}>
                      {productDetails.makroUrl ? (
                        <>
                          <Box display="flex" justifyContent="center" className="partnerLogos1">
                            {productDetails.makroUrl !== null && productDetails.makroUrl !== undefined && productDetails.makroUrl !== '' ? <a target="_blank" href={productDetails.makroUrl}> <img src={makro1} style={{ width: '100%', height: 'auto' }} /></a> : <></>}
                          </Box>
                        </>
                      ) : productDetails.takealotUrl ? (
                        <>
                          <Box display="flex" justifyContent="center" className="partnerLogos1">
                            {productDetails.takealotUrl !== null && productDetails.takealotUrl !== undefined && productDetails.takealotUrl !== '' ? <a target="_blank" href={productDetails.takealotUrl}> <img src={takealot1} style={{ width: '100%', height: 'auto' }} /></a> : <></>}
                          </Box>
                        </>
                      ) : null}

                    </Grid>
                  ) : null}
                </Grid>
              </Item>
            </Grid>
          </Grid>
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><RingLoader color="#36d7b7" /></div>

        </div>
      )}
    </>


  );
}
