import React from 'react'
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';


export interface MapsProps {
  width?: string;
  heigh?: string;
  lat: number;
  lng: number;
}

function Maps(props: MapsProps) {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_TOKEN!
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map: any) {

    map.setZoom(14)

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: props.width,
        height: props.heigh
      }}
      center={{
        lat: props.lat,
        lng: props.lng
      }}
      zoom={14}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      { /* Child components, such as markers, info windows, etc. */}
      <>
        <MarkerF
          position={{ lat: props.lat, lng: props.lng }}
        />
      </>
    </GoogleMap>
  ) : <></>
}

export default React.memo(Maps)