// components/Home.js
import { Heading } from '@aws-amplify/ui-react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import * as React from 'react';
import './400.css';

export function NotFoundPage() {
  return (
    <>
      <Container sx={{ backgroundColor: "fff" }}>
        <Heading level={3}>
          <Typography className='notf' variant="h1"  sx={{fontSize: {xs: 75, sm: 100, md: 200, lg: 332, xl: 300}}} mt={20}>
            404
          </Typography>
        </Heading>

        <Typography variant="h3">This page cannot be found.</Typography>
      </Container>

    </>
  );
}