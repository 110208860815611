// components/Home.js
import { Heading } from '@aws-amplify/ui-react';
import { RingLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { getItem } from "../../services/api.svs";
import Team from '../../images/team/team.jpg';
import IndustrialImage from '../../images/industrial-img/industrial-image.png';
import ProductFull from '../../images/products-full/product-full.png';
import './home.css';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { CardActionArea, CardActions } from '@mui/material';
// import { Auth } from 'aws-amplify';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#000',
  // padding: theme.spacing(31),
  textAlign: 'center',

}))

export function Home() {
  const [isLoaded, setIsLoaded] = useState(false)
  const [bestSelling, setBestSelling] = useState<any[]>([])
  const [mostRecent, setMostRecent] = useState<any[]>([])


  useEffect(() => {
    const getData = async () => {
      // const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem('/products/bestSelling').then(resp => {
        console.log("BestSeller", resp.data)
        setBestSelling(resp.data)

      }).catch(resp => {
        setBestSelling([])

      })
      //stop loading when data is fetched
      getItem('/products/mostRecent').then(resp => {
        setMostRecent(resp.data)
        setIsLoaded(true)
      }).catch(resp => {
        setMostRecent([])
        setIsLoaded(true)

      })
    }
    getData()
  }, [])


  return (
    <>
      {isLoaded ? (
        <>
          <Container sx={{ marginTop: "130px" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} columnSpacing={4} sx={{ marginBottom: "90px" }}>
                <Grid className='home-text' item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Item>
                    <Typography variant="h2" color="secondary" fontSize={"29px"} mt={6}>WELCOME</Typography>
                    <br />
                    <Typography variant="h4" mt={1} mb={5} align="center" sx={{ fontSize: { xs: 14, sm: 16, md: 17, lg: 29, xl: 20 } }}>Dedicated to being the number one supplier of quality products in South Africa. We offer a wide
                      variety of lubricants for all your needs.</Typography>

                    <Typography variant="body2">Call us today for a quote!</Typography>
                    <br />
                    <Button
                      component={Link} to="/contact-us"
                      variant="contained"
                      sx={{
                        mt: 2,
                        width: 129,
                        height: 50,
                        backgroundColor: "#FFFFFF",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 13,
                        lineHeight: 29,
                        color: "#000000",
                        '&:hover': {
                          backgroundColor: "#383536",
                          color: "#8dc74a",
                          height: 55,
                          width: 132,
                          transition: 0.8,
                          borderRadius: 1
                        }
                      }}>Contact us</Button></Item>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Item >
                    <Card sx={{ maxWidth: "681px" }}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          
                          image={Team}
                          alt="Our team photo"
                          sx={{ objectFit: "cover",
                        aspectRatio: "4/3"}}
                        />
                      </CardActionArea>
                    </Card>
                  </Item>
                  {/* <img style={{ width: "570px" }} className="team-top" src={Team} alt="Our team photo" /></Item> */}
                </Grid>


                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                  <section className="middle-section row">
                    <div className="best-sellers" style={{ marginBottom: "50px" }}>
                      <Typography variant="h4" mt={2} mb={3}>Best Sellers</Typography>
                    </div>


                    {bestSelling.map((bestSelling) =>
                      <Card sx={{ maxWidth: 210, ml: "12px", mr: "12px", mb: "24px" }}>
                        <div className="cardMedia" style={{ height: "200px" }}>
                          <a href={`/products/${bestSelling.productTypeId}/${bestSelling.productId}`}>
                            <CardMedia component="img"
                              // sx={{ height: 200 }}
                              image={process.env.REACT_APP_NOVAS_IMAGE_BASE + bestSelling.productUrlSmall}
                            />
                          </a>
                        </div>
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            <h2>{bestSelling.productName}</h2>
                          </Typography>
                        </CardContent>
                      </Card>)}
                  </section>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Item>
                    <Card sx={{ maxWidth: "681px" }}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="428px"
                          image={ProductFull}
                          alt=""
                        />
                      </CardActionArea>
                    </Card>
                    {/* <img style={{ maxWidth: "570px" }} className="team-img" src={ProductFull} alt="" /> */}
                  </Item>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Item className="home-text">
                    <Typography variant="h2" mt={6} mb={6}>PRODUCTS</Typography>

                    <Typography variant="body2">We have over 100 products ranging from  lubricants, additives and automative products  for all your automotive needs.
                      Call us for a quote today.
                    </Typography>

                    <Button
                      component={Link} to="/product-types"
                      variant="contained"
                      sx={{
                        width: 159,
                        height: 50,
                        backgroundColor: "#FFFFFF",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 13,
                        lineHeight: 29,
                        color: "#000000",
                        mt: 6,
                        '&:hover': {
                          backgroundColor: "#383536",
                          color: "#8dc74a",
                          height: 55,
                          width: 155,
                          transition: 0.8,
                          borderRadius: 1
                        }
                      }}>More products</Button>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <section className="middle-section row">
                    <div className="best-sellers" style={{ marginBottom: "50px" }}>
                      <Typography variant="h4" mt={2} mb={3}>Most Recent</Typography>
                    </div>

                    {mostRecent.map((mostRecent) =>
                      <Card sx={{ maxWidth: 210, ml: "12px", mr: "12px", mb: "24px" }}>
                        <div className="cardMedia" style={{ height: "200px" }}>
                          <a href={`/products/${mostRecent.productTypeId}/${mostRecent.productId}`}>
                            <CardMedia component="img"
                              // sx={{ height: 200 }}
                              image={process.env.REACT_APP_NOVAS_IMAGE_BASE + mostRecent.productUrlSmall}
                            />
                          </a>
                        </div>
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            <h2>{mostRecent.productName}</h2>
                          </Typography>
                        </CardContent>
                      </Card>)}
                  </section>
                </Grid>

                <Grid item className="top-section row" xs={12} sm={12} md={6} lg={6} xl={6} mb={"30px"}>
                  <Item className="home-text" >
                    <Typography variant="h2" mt={3}>Novas Industrial</Typography>
                    <br />
                    <Typography variant="body2" align="left">Transformer oil regeneration service.
                      We regenerate transformer oil to meet all SANS 555 (BS148) specifications. All testing is done by an
                      independent lab that is SABS accredited. We also offer bulk storage or banking of old oil to be drawn by
                      the client as required.
                    </Typography>
                    <br />
                    <Button
                      component={Link} to="/faq"
                      variant="contained"
                      sx={{
                        width: 159,
                        height: 50,
                        backgroundColor: "#FFFFFF",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 13,
                        lineHeight: 29,
                        color: "#000000",
                        mt: 1,
                        '&:hover': {
                          backgroundColor: "#383536",
                          color: "#8dc74a",
                          height: 55,
                          width: 155,
                          transition: 0.8,
                          borderRadius: 1
                        }
                      }}>Learn more</Button>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mb={"30px"}>
                  <Item>
                    <Card sx={{ maxWidth: "681px" }}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="428px"
                          image={IndustrialImage}
                          alt=""
                        />
                      </CardActionArea>
                    </Card>
                    {/* <img style={{ maxWidth: "570px" }} className="team-img" src={IndustrialImage} alt="" /> */}
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><RingLoader color="#36d7b7" /></div>

        </div>
      )}
    </>
  );
}
