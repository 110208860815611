// components/Home.js
import { Heading } from '@aws-amplify/ui-react';
import { Link, useParams } from 'react-router-dom';
import { RingLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { getItem } from '../../services/api.svs';
import './productTypes.css';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CardActionArea, CardActions } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import makro  from "../../images/businessPartners/Makro.webp";
import leroyMerlin  from "../../images/businessPartners/leroy-merlin.webp";
import takealot  from "../../images/businessPartners/TakeAlot.webp";

export function ProductTypes() {


  const [isLoaded, setIsLoaded] = useState(false)
  const [productType, setProductType] = useState<any[]>([])

  useEffect(() => {
    window.scrollTo(0, 0);
   
    const getProductType = async () => {
      // const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem('/product_types').then(resp => {
        setProductType(resp.data)
        setIsLoaded(true)
      }).catch(resp => {
        setProductType([])
        setIsLoaded(true)
        
      })
    }
    getProductType()
  }, [])

  const sortedProductType = productType.slice().sort((a, b) => {
    const nameA = a.productTypeName.toUpperCase(); // ignore upper and lowercase
    const nameB = b.productTypeName.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0; // names must be equal
  });

  return (
    <>
      {isLoaded ? (
        <>
          <Container maxWidth="xl" sx={{paddingTop: "30px"}}>

            <div className="dd" style={{
              backgroundColor: "#fff",
            }}>
              <Heading level={3} color="#000">
                Product Range
              </Heading>
              <br />
              <br />
              <div className="dd" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: "100%"
              }}>
                <Grid container spacing={3}
                  alignItems="center"
                  justifyContent="center"
                  style={{ width: "95%" }}>
                  {sortedProductType.map((product_type) =>
                    <Grid item lg={2.4} md={4} xs={12}>
                      <Card >
                        <CardActionArea>
                          <Typography variant="h2" fontSize={"17px"} color="primary" sx={{ marginTop: 3, fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, height: "5vh" }}>
                            {product_type.productTypeName}
                          </Typography>
                          <a href={`/products/${product_type.productTypeId}`}>
                            <CardMedia
                              component="img"
                              height="auto"
                              image={process.env.REACT_APP_NOVAS_IMAGE_BASE + product_type.productTypeUrl}
                              alt={product_type.productTypeName}
                              sx={{ height: 300, objectFit: "contain", width: "100%", display: 'flex' }}
                            /></a>
                        </CardActionArea>
                        
                        <CardActions>
                          <Button component={Link} to={`/products/${product_type.productTypeId}`}
                            size="small" color="primary" sx={{
                              display: "flex",
                              backgroundColor: "#fff",
                              textDecoration: "none",
                              border: "#000 1px solid",
                              borderRadius: "4px",
                              height: "30px",
                              width: "210px",
                              margin: "13px auto",
                              '&:hover': {
                                backgroundColor: "#84878C",
                                border: "2px solid #84878C",
                                color: "#000"
                              }
                            }} endIcon={<ArrowForwardIcon />}>
                            View Range
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>)}
                </Grid>
              </div>
              <br />
            </div>

          </Container>

          {/* <Container>
            <Grid className="products-container" mt={17}>
              {productType.map((product_type) =>
                <div className="item">
                  <h2>{product_type.productTypeName}</h2>
                  <img src={process.env.REACT_APP_NOVAS_IMAGE_BASE + product_type.productTypeUrl} alt={product_type.productTypeName} />

                  <Link to={`/products/${product_type.productTypeName}`}>
                    <p>View range<i className="bi bi-arrow-right"></i></p>
                  </Link>
                </div>)}
            </Grid>
          </Container> */}
          {/* 
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            {productType.map((product_type) =>
              <Card sx={{ maxWidth: 200, ml: "10px", mr: "10px", mb: "24px" }}>
                <div className="cardMedia" style={{ height: "200px" }}>
                  <CardMedia component="img"
                    // sx={{ height: 200 }}
                    image={process.env.REACT_APP_NOVAS_IMAGE_BASE + product_type.productTypeUrl}
                  />
                </div>
                <CardContent>
                  <Typography gutterBottom variant="body2" fontSize={"5px"} component="div">
                    <Link to={`/products/${product_type.productTypeName}`}  >
                      <Button variant="outlined">View Range</Button>
                    </Link>
                    <h2>{product_type.productTypeName}</h2>
                  </Typography>
                </CardContent>
              </Card>)}
          </Grid> */}

        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><RingLoader color="#36d7b7" /></div>

        </div>
      )
      }
    </>
  );
}